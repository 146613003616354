import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import classnames from 'classnames';

import { Coach } from '../../../../../domain/models';

export interface DesignateCoachPopupProps {
    icon?: string,
    coachList?: Coach[],
    coachName?: any;
    isVisible: boolean;
    onClickDesignate?: (coachId: number) => void;
    onClose: () => void;
}

export interface RequestList {
    promise: Promise<string>,
    traineeID: number

}

export const DesignateCoachPopup: React.FunctionComponent<DesignateCoachPopupProps> = ({ isVisible, icon, coachList, coachName, onClickDesignate, onClose }) => {
    const { t } = useTranslation();
    const selectCoachLbl = t('DesignateCoachPopup.selectCoach');
    const [openDropdownMenu, toggleDropdownMenu] = useState(false);
    const [coach, changeCoach] = useState(selectCoachLbl);
    const [buttonEnable, changeCoachName] = useState(coachName as string | undefined);
    const [selectedCoachID, updateSelectedCoach] = useState(0)

    const coachChange = (coach: string, coachId: number) => {
        changeCoach(coach)
        updateSelectedCoach(coachId)
        toggleDropdownMenu(false)
        changeCoachName(coach);
    }

    const confirmDesignateCoachHandler = () => {
        if (onClickDesignate) {
            onClickDesignate(selectedCoachID)
        }
    }

    useEffect(() => {
        if (!isVisible) {
            changeCoach(selectCoachLbl)
        } else {
            if (!coachName) {
                changeCoach(selectCoachLbl);
                changeCoachName(coachName);

            }
            else {
                changeCoach(coachName);
                changeCoachName(coachName);
            }
        }

    }, [isVisible])

    return (
        <span className="coach-trainee-popup">
            <Modal open={isVisible} onClose={() => onClose()}>
                <h6>{t('Popup.ChangeAssigncoach')}</h6>
                <p>
                    {t('Popup.SelectCoachMessage')}
                </p>
                <div className="btn-group d-block mb-3">
                    <div className="dropdown">
                        <button className={'btn dropdown-toggle btn-light'} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropdownMenu(!openDropdownMenu)} style={{ width: "100%" }}>
                            {icon && <i className={`${icon} d-inline-block mr-2`}></i>}
                            {coach}
                        </button>
                        <div className={classnames('dropdown-menu dropdown-menu-right', { 'show': openDropdownMenu })}  style={{ width: "100%" }}>
                            {coachList !== undefined &&
                                coachList.map((coach, i) =>
                                    (<div key={i}>
                                        <li className="dropdown-item">
                                            <div className="form-check">
                                                <label className="" onClick={() => coachChange(coach.name, coach.id)}>{coach.name}</label>
                                            </div>
                                        </li>
                                    </div>)
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => onClose()}>{t('miscelaneous.close')}</button>
                    <button type="button" className="btn btn-primary" disabled={!buttonEnable} onClick={confirmDesignateCoachHandler}>{t('Popup.ChangeAssigncoach')}</button>
                </div>
            </Modal>
        </span>
    );
}
