import * as React from 'react';

import { Auth } from '../../domain/_shared';

import { MembersMgmtContextProvider } from './GroupManagementPage/MembersMgmtContext';
import { ParticipantsMgmtContextProvider } from './CourseManagementPage/components/tab2/ParticipantsMgmtContext';

import { GroupManagementPage, GroupManagementPageRouteParameters } from './GroupManagementPage';
import { CreateNewCoursePage, CreateNewCoursePageRouteParameters } from './CreateNewCoursePage';
import { CourseEditionPage, CourseEditionPageRouteParameters } from './CourseEditionPage';
import { CourseManagementPage, CourseManagementPageRouteParameters } from './CourseManagementPage';
import { CourseAddTraineePage, CourseAddTraineePageRouteParameters } from './CourseAddTraineePage';
import { CourseAddCoachPage, CourseAddCoachPageRouteParameters } from './CourseAddCoachPage';
import { GroupEditionPageRouteParameters, GroupEditionPage } from './GroupEditionPage';

export const adminRoutes = (auth: Auth, handleLogout: () => void): [string[], Function][] => {
    return [
        [['groups', ':groupId', 'admin',':tabId'],
        (params: GroupManagementPageRouteParameters) => (
            <MembersMgmtContextProvider auth={auth} groupId={params.groupId}>
                <GroupManagementPage
                    auth={auth!}
                    onLogout={() => handleLogout()}
                    groupId={params.groupId}
                    tabId={params.tabId}

                />
            </MembersMgmtContextProvider>
        )],
        [['groups', ':groupId', 'admin', 'edit',':tabId'],
        (params: GroupEditionPageRouteParameters) => (
            <GroupEditionPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
            />
        )],
        [['groups', ':groupId', 'admin',':tabId', 'newcourse'],
        (params: CreateNewCoursePageRouteParameters) => (
            <CreateNewCoursePage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                tabId={params.tabId}

            />
        )],
        [['groups', ':groupId', 'admin', 'edit', 'courses', ':courseId'],
        (params: CourseEditionPageRouteParameters) => (
            <CourseEditionPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
            />
        )],
        [['groups', ':groupId', 'admin', 'courses', ':courseId',':tabId'],
        (params: CourseManagementPageRouteParameters) => (
            
            <ParticipantsMgmtContextProvider auth={auth} courseId={params.courseId}>
                <CourseManagementPage
                    auth={auth!}
                    onLogout={() => handleLogout()}
                    groupId={params.groupId}
                    courseId={params.courseId}
                    tabId={params.tabId}
                />
            </ParticipantsMgmtContextProvider>
        )],
        [['groups', ':groupId', 'admin', 'courses', ':courseId',':tabId', 'addtrainee'],
        (params: CourseAddTraineePageRouteParameters) => (
            <CourseAddTraineePage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
                tabId={params.tabId}
            />
        )],
        [['groups', ':groupId', 'admin', 'courses', ':courseId',':tabId', 'addcoach'],
        (params: CourseAddCoachPageRouteParameters) => (
            <CourseAddCoachPage
                auth={auth!}
                onLogout={() => handleLogout()}
                groupId={params.groupId}
                courseId={params.courseId}
                tabId={params.tabId}
            />
        )],
    ]
}
