import * as React from 'react';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import * as Router from '../api/Router';

import { Body } from './Body';

const brand = require('../assets/img/logo.png');

export interface HeaderProps {
    onNavbarToggleClick: () => void;
}

export const Header: React.FunctionComponent<HeaderProps> = ({ onNavbarToggleClick, children }) => {
    return (

        <Body className={'header-fixed'}>
            <header className="app-header navbar">
                {/* <button className="navbar-toggler " type="button">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                <a className="navbar-brand" href="javascript:void(0)" style={{ backgroundImage: `url(${brand})` }} onClick={() => Router.push('/')}></a>
                <button className="navbar-toggler" type="button" onClick={onNavbarToggleClick}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                {children}
            </header>
        </Body>
    );
};

export interface HeaderUserProfileProps {
    name: string;
    onLogout: () => void;
}

export const HeaderUserProfile: React.FunctionComponent<HeaderUserProfileProps> = ({ name, onLogout, children }) => {
    const { t } = useTranslation();
    const [openProfileMenu, toggleProfileMenu] = useState(false);

    let blurId: NodeJS.Timeout;
    function handleOnBlur() {
        blurId = setTimeout(() => {
            if (openProfileMenu) {
                toggleProfileMenu(!openProfileMenu);
            }
        }, 300);
    }

    return (
        <ul
            className="nav navbar-nav ml-auto mr-3 no_selection d-none d-md-block"
            tabIndex={0}
            onBlur={handleOnBlur}
        >
            <li className={classnames('nav-item dropdown', { 'open show': openProfileMenu })} data-placement="bottom right">
                <a
                    aria-haspopup="true"
                    className="nav-link avatar text-white"
                    role="button"
                    style={{ cursor: 'pointer' }}
                    aria-expanded={openProfileMenu}
                    onClick={() => toggleProfileMenu(!openProfileMenu)}
                >
                    <div className="d-flex align-items-center">
                        <span className="profile-name text-right mr-2 text-muted" style={{ fontSize: '.75rem' }}>
                            <span className="lead">{t('Header.greeting')}, {name}</span>
                        </span>
                        <Avatar name={name} round={true} size="30px" />
                        <div className="ml-1"><span className="fa fa-angle-down"></span></div>
                    </div>
                </a>

                <div
                    aria-labelledby="simple-dropdown"
                    className={classnames('dropdown-menu dropdown-menu-right', { 'show': openProfileMenu })}
                    style={{ marginTop: 5, left: 'auto', right: 0, top: '100%', transform: 'translateY(0px)' }}
                >
                    {children}
                    <div className="divider"></div>
                    <a className="dropdown-item" href="javascript:void(0)" onClick={() => { clearTimeout(blurId); onLogout(); }} style={{ padding: '8px 20px' }}>
                        <i className="icon-logout text-muted"></i> {t('Header.logout')}
                    </a>
                </div>
            </li>
        </ul>
    );
}

export interface HeaderUserProfileContextMenuItemProps {
    className?: string;
}

export const HeaderUserProfileContextMenuItem: React.FunctionComponent<HeaderUserProfileContextMenuItemProps> = ({ className, children }) => {
    return (
        <div className={classnames('dropdown-header', className)} style={{ padding: '8px 20px' }}>
            {children}
        </div>
    );
}