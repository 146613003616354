import * as React from 'react';

import * as Router from '../../../api/Router';

import { Auth } from '../../../domain/_shared';

import { P404 } from '../../../components/P404';
import { ContentPage } from './ContentPage';
import { MeetingPage } from './MeetingPage';
import { EditableFormPage } from './EditableFormPage';
import { QuizPage } from './QuizPage';
import { SurveyPage } from './SurveyPage';

export interface TaskPageRouteParameters {
    groupId: number;
    courseId: number;
    traineeId: number;
    lessonId: number;
    taskId: number;
    taskType: 'content' | 'meeting' | 'editableform' | 'quiz' | 'survey';
}

export interface TaskIndexPageProps extends TaskPageProps {
    taskType: 'content' | 'meeting' | 'editableform' | 'quiz' | 'survey';
}

interface TaskPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export const TaskPage: React.FunctionComponent<TaskIndexPageProps> = ({ taskType, ...taskProps }) => {
    return (
        getTaskByType(taskType, taskProps)
    );
}

const getTaskByType: ((t: string, p: TaskPageProps) => JSX.Element) = (taskType: string, taskProps: TaskPageProps) => {
    switch (taskType) {
        case 'content':
            return <ContentPage {...taskProps}></ContentPage>
        case 'meeting':
            return <MeetingPage {...taskProps}></MeetingPage>
        case 'editableform':
            return <EditableFormPage {...taskProps}></EditableFormPage>
        case 'quiz':
            return <QuizPage {...taskProps}></QuizPage>
        case 'survey':
            return <SurveyPage {...taskProps}></SurveyPage>
        default:
            return <P404 onGoBack={() => Router.push('/')} />
    }
}

