import * as React from 'react';

import * as Router from '../../../../../api/Router';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';

import { Course } from '../../../../../domain/models';
import { useReducer } from 'react';

import { CardViewType } from '../../../../../components/Cards/CardSection';
import { ButtonAdd } from '../../../../../components/Buttons/ButtonAdd';
import { AdminCourseStatusCard } from './AdminCourseStatusCard';
import { ButtonDashed } from '../../../../../components/Buttons/ButtonDashed';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../../../../src/api/react-helper';

import { Button } from '../../../../../components/Buttons/Button';  // (TODO) remove after refactoring components
import { AuthContext } from '../../../../../domain/contexts/AuthContext';
import { useContext } from 'react';
import * as AdminActions from '../../actions';
import { isRequestError } from '../../../../../domain/_shared';
import * as services from '../../../../../domain/services';
import { LoadingPage } from '../../../../../components/LoadingPage';
import _ from 'lodash';
import { P50x } from '../../../../../components/P50x';


export interface GroupCoursesListProps {
    groupId: number;
   //courses: Course[];
}

type State={
    apiError?: any | undefined;
    courses?: Course[];
}

type Action = {
    Set50xError(apiError: any): State;
    LoadGroupCourses(courses: Course[]): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadGroupCourses: courses => ({ ...state, courses }),
})


export const GroupCoursesList: React.FunctionComponent<GroupCoursesListProps> = ({ groupId }) => {
 const { state: { auth } } = useContext(AuthContext);
 const [{ apiError,courses }, actions] = wrapperReducer(useReducer(reducer, { }));
 const [publishedCourseId, setPublishedCourseId] = React.useState()
 const [publishPopUp, setPublishPopUp] = React.useState(false)

 const { t } = useTranslation();
 const goToRoot = () => Router.push('/');

    useLoadGroupCourses(groupId, actions);   
    if (!courses) return <LoadingPage />;
    if (apiError) return <P50x onGoBack={goToRoot} />

    const deleteCourseHandler = (courseId:number) => {
       
            try {
           
                AdminActions.deleteCourse(auth!,courseId).then((result)=>{
                    _.remove(courses, { id: courseId });
                      if (isRequestError(result)) {
            
                        } else {
                            actions.send(a => a.LoadGroupCourses(courses));
                        }               
                })      
                
            }
            catch (e) {
                actions.send(a => a.Set50xError(e));
            }
    }

    const publishButtonHandler = (coursId: number) => {
          setPublishedCourseId(coursId) 
          setPublishPopUp(true)
    }

    const publishConfirmHandler = () =>{
      setPublishPopUp(false)
      services.updateCourseStatus(auth!, publishedCourseId, 'Published')
        .then(()=> {
          Router.push(`/groups/${groupId}/admin/courses/${publishedCourseId}/0`)
          Router.push(`/groups/${groupId}/admin/1`)
        })
        .catch((error) => {
          actions.send(a=> a.Set50xError(error))
        })
    }
   
    return (
   
        <div className="mt-1 mt-md-4">
            <div className="container-fluid">
               
                <div className="btn-align-right">
                    <ButtonAdd active={true} label={'New Course'} onAdd={() => Router.push(`/groups/${groupId}/admin/1/newcourse`)} />
                </div>
                {/** (TODO) handle i18n */}

                <div className="row">
                    {courses.map((course, i) => (
                        <AdminCourseStatusCard
                            key={i}
                            cardViewType={CardViewType.List}
                            name={course.name}
                            totalTrainees={course.traineeCount}
                            totalCoaches={course.type === 'CoachAssisted' ? course.coachCount : undefined}
                            publishDate={course.publishDate}
                            courseStatus={course.status!}
                            onClick={() => Router.push(`/groups/${groupId}/admin/courses/${course.id}/0`)}
                            onClickPublish={() => publishButtonHandler(course.id)}
                            onClickDeleteCourse={() => {deleteCourseHandler(course.id)}}
                            groupId={groupId}
                            courseId={course.id}
                            courseType={course.type}
                        />
                    ))}
                   
                    <ButtonDashed icon={'icon fa fa-plus'} label={'New Course'} onClick={() => Router.push(`/groups/${groupId}/admin/1/newcourse`)} />
                    {/** (TODO) handle i18n */}
                   
                </div>
            </div>
            <Modal open={publishPopUp} onClose={() => setPublishPopUp(false)} center>
            <br/>
                <p>
                    {'You will not be able to edit the course, are you sure you want to publish'}
                </p> 
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => setPublishPopUp(false)}>{t('miscelaneous.cancel')}</button>
                    <button type="button" className="btn btn-primary"  onClick={publishConfirmHandler}>{t('miscelaneous.yes')}</button>

                </div>
            </Modal>
        </div>
    )
}

function useLoadGroupCourses(groupId: number, actions: ActionDispatcher<Action, State>) {
    const { state: { auth } } = useContext(AuthContext);
    useAsyncEffect(async () => {
        try {
           
            const courses=  AdminActions.getGroupCourses(auth!,groupId)
            .then((result)=>{
                  if (isRequestError(result)) {
        
                    } else {
                        actions.send(a => a.LoadGroupCourses(result));
                    }               
            })      
            
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}
