import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import * as Router from '../../api/Router';
import * as Sli from '../../components/Icons';

import { UserContext } from '../../domain/contexts/UserContext';

import { Header, HeaderUserProfile, HeaderUserProfileContextMenuItem } from '../../components/Header';
import { AppBody } from '../../components/AppBody';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { SidebarNav, SidebarNavItemm } from '../../components/SidebarNav';
import { Breadcrumb, BreadcrumbItem, BreadcrumbItemProps } from '../../components/Breadcrumb';
import { Footer } from '../../components/Footer';

export interface BaseAppLayoutProps {
    breadcrumbs?: BreadcrumbItemProps[];
    onLogout: () => void;
}

export const BaseAppLayout: React.FunctionComponent<BaseAppLayoutProps> = ({ breadcrumbs, onLogout, children }) => {
    const { state: { presets } } = useContext(UserContext);
    const { t } = useTranslation();
    const [{ showSidebar }, setState] = useState({ showSidebar: false });

    function closeSideBar(): void {
        setState({ showSidebar: false })
    }

    return (
        <>
            <Header onNavbarToggleClick={() => setState({ showSidebar: !showSidebar })} >
                <HeaderUserProfile name={presets ? presets.nickName : '(unknown)'} onLogout={onLogout}>
                    <HeaderUserProfileContextMenuItem><span style={{ cursor: 'pointer' }} onClick={() => Router.push('/profile')}>{t('BaseAppLayout.profile')}</span></HeaderUserProfileContextMenuItem>
                </HeaderUserProfile>
            </Header>
            <AppBody>
                <ErrorBoundary>
                    {showSidebar && (
                        <SidebarNav hidden={!showSidebar} name={presets ? presets.nickName : '(unknown)'}>
                            <SidebarNavItemm icon={Sli.home} href={'javascript:void(0)'} text={t('BaseAppLayout.home')} goTo={() => { closeSideBar(); Router.push('/'); }} />
                            <SidebarNavItemm icon={Sli.home} href={'javascript:void(0)'} text={t('BaseAppLayout.profile')} goTo={() => { closeSideBar(); Router.push('/profile'); }} />
                            <SidebarNavItemm icon={Sli.bookOpen} href={'javascript:void(0)'} text={t('BaseAppLayout.myCourses')} goTo={() => { closeSideBar(); Router.push('/mycourses'); }} />
                            <SidebarNavItemm icon={Sli.people} href={'javascript:void(0)'} text={t('BaseAppLayout.myGroups')} goTo={() => { closeSideBar(); Router.push('/mygroups'); }} />
                            <SidebarNavItemm icon={Sli.logout} href={'javascript:void(0)'} text={t('BaseAppLayout.logout')} goTo={onLogout} />
                        </SidebarNav>
                    )}
                    <main className={'main'} onClick={closeSideBar}>
                        {breadcrumbs && (
                            <Breadcrumb>
                                {breadcrumbs.map((bc, i) => <BreadcrumbItem key={i} {...bc} />)}
                            </Breadcrumb>
                        )}
                        <div className={classnames('container-fluid', { 'pt-4': !breadcrumbs })}>
                            {children}
                        </div>
                    </main>
                </ErrorBoundary>
            </AppBody>

            <Footer
                onTermsClick={() => Router.push('/terms')}
                onPrivacyClick={() => Router.push('/privacy')}
            ></Footer>
        </>
    );
}
