import { __SERVER_API__ } from '.';
import { Auth, get, post, remove, update, postImage, updateImage } from '../_shared';
import {
    AdminGroup,
    MembersList,
    AdminCourse,
    CourseLessonsAggregate,
    CourseProgressData,
    ParticipantsList,
    Coach,
    CourseConfigs,
    LessonConfigs,
    ContentConfigs,
    Question,
    Survey,
    QuestionOption,
    SubQuestion,
    Content,
    Form,
    GroupConfigs
} from '../models';

export async function getAdminGroup(auth: Auth, groupId: number): Promise<AdminGroup> {
    return await get(`${__SERVER_API__}/api/groups/${groupId}`, auth);
}

export async function approveMembership(auth: Auth, groupId: number, memberId: number): Promise<string> {
    return await post(`${__SERVER_API__}/api/groups/${groupId}/members/${memberId}/approve`, auth, undefined);
}

export async function getMembers(auth: Auth, groupId: number, searchString?: string, membershipStatus?: string[], isAdmin?: boolean, sortBy?: string, page?: number, resultsPerPage?: number): Promise<MembersList> {
    if (searchString == undefined) {
        searchString = "";
    }
    if (sortBy == undefined) {
        sortBy = "";
    }

    let membershipStatusUrl = "";
    if (membershipStatus != undefined) {
        const status = membershipStatus.map(function (status) {
            membershipStatusUrl = membershipStatusUrl.concat('&membershipStatus=' + status);
        });
    }

    if (membershipStatusUrl != "") {
        return await get(`${__SERVER_API__}/api/groups/${groupId}/members?search=${searchString}&sortBy=${sortBy}&isAdmin=${isAdmin}&page=${page}&resultsPerPage=${resultsPerPage}` + membershipStatusUrl, auth);
    }

    else {
        return await get(`${__SERVER_API__}/api/groups/${groupId}/members?search=${searchString}&sortBy=${sortBy}&isAdmin=${isAdmin}&page=${page}&resultsPerPage=${resultsPerPage}`, auth);
    }
}

export async function setAsAdmin(auth: Auth, groupId: number, memberId: number): Promise<void> {
    return await update<string, void>(`${__SERVER_API__}/api/groups/${groupId}/members/${memberId}/role`, auth, 'Admin');
}

export async function setAsNonAdmin(auth: Auth, groupId: number, memberId: number): Promise<void> {
    return await update<string, void>(`${__SERVER_API__}/api/groups/${groupId}/members/${memberId}/role`, auth, 'Member');
}

export async function getAdminCourse(auth: Auth, courseId: number): Promise<AdminCourse> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}`, auth);    // (TODO) differentiate from getParticipantCourse
}

export async function getLessonsAggregate(auth: Auth, courseId: number): Promise<CourseLessonsAggregate> {
    //  return await get(`${__SERVER_API__}/api/courses/${courseId}/lessonsAggregate`, auth);// (TODO) pending endpoint
    return new Promise((resolve, reject) => {
        resolve([
            { id: 1, name: 'Lesson1' },
            { id: 2, name: 'Lesson2' }
        ])
    });
}

export async function getCourseProgress(auth: Auth, courseId: number): Promise<CourseProgressData> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}/progress`, auth);
}

export async function approveUser(auth: Auth, courseId: number, traineeId: number): Promise<string> {
    return await post(`${__SERVER_API__}/api/courses/${courseId}/trainees/${traineeId}/approve`, auth, undefined);
}

export async function getTrainees(auth: Auth, courseId: number, searchString?: string, coachPending?: boolean, coachList?: number[], traineeStatus?: string[], enrollmentStatusList?: string[], sortBy?: string, page?: number, resultsPerPage?: number): Promise<ParticipantsList> {
    if (searchString == undefined) {
        searchString = "";
    }
    if (sortBy == undefined) {
        sortBy = "";
    }
    if (coachPending == undefined) {
        coachPending = false;
    }
    let coachListUrl = "";
    let traineeStatusUrl = "";
    let enrollmentStatusUrl = "";
    if (coachList != undefined) {
        const coachId = coachList.map(function (coachId) {
            coachListUrl = coachListUrl.concat('&coachId=' + coachId);
        });
    }
    if (traineeStatus != undefined) {
        const status = traineeStatus.map(function (status) {
            traineeStatusUrl = traineeStatusUrl.concat('&traineeStatus=' + status);
        });
    }
    if (enrollmentStatusList != undefined) {
        const enrollmentStatus = enrollmentStatusList.map(function (enrollmentStatus) {
            enrollmentStatusUrl = enrollmentStatusUrl.concat('&enrollmentStatus=' + enrollmentStatus);
        });
    }
    if (coachListUrl != "" && traineeStatusUrl != "" && enrollmentStatusUrl != "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + coachListUrl + traineeStatusUrl + enrollmentStatusUrl, auth);
    }
    else if (coachListUrl != "" && traineeStatusUrl != "" && enrollmentStatusUrl == "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + coachListUrl + traineeStatusUrl, auth);
    }
    else if (coachListUrl != "" && traineeStatusUrl == "" && enrollmentStatusUrl != "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + coachListUrl + enrollmentStatusUrl, auth);
    }
    else if (coachListUrl != "" && traineeStatusUrl == "" && enrollmentStatusUrl == "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + coachListUrl, auth);
    }
    else if (coachListUrl == "" && traineeStatusUrl != "" && enrollmentStatusUrl != "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + traineeStatusUrl + enrollmentStatusUrl, auth);
    }
    else if (coachListUrl == "" && traineeStatusUrl != "" && enrollmentStatusUrl == "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + traineeStatusUrl, auth);
    }
    else if (coachListUrl == "" && traineeStatusUrl == "" && enrollmentStatusUrl != "") {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}` + enrollmentStatusUrl, auth);
    }
    else {
        return await get(`${__SERVER_API__}/api/courses/${courseId}/trainees?search=${searchString}&sortBy=${sortBy}&coachPending=${coachPending}&page=${page}&resultsPerPage=${resultsPerPage}`, auth);
    }
}

export async function getCoaches(auth: Auth, courseId: number): Promise<Coach[]> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}/coaches`, auth);
}

export async function assignCoach(auth: Auth, courseId: number, traineeId: number, postData: number | null): Promise<string> {
    return await update<number | null, string>(`${__SERVER_API__}/api/courses/${courseId}/trainees/${traineeId}/coach`, auth, postData);
}

export async function removeCoach(auth: Auth, courseId: number, coachId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/courses/${courseId}/coaches/${coachId}`, auth);
}

export async function getTraineeCandidates(auth: Auth, courseId: number, searchString: string): Promise<MembersList> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}/traineeCandidates?search=${searchString}`, auth);
}

export async function addTrainee(auth: Auth, courseId: number, postData: number | null): Promise<string> {
    return await post<number | null, string>(`${__SERVER_API__}/api/courses/${courseId}/trainees`, auth, postData);
}

export async function getCoachCandidates(auth: Auth, courseId: number, searchString: string): Promise<MembersList> {
    return await get(`${__SERVER_API__}/api/courses/${courseId}/coachCandidates?search=${searchString}`, auth);
}

export async function addCoach(auth: Auth, courseId: number, postData: number | null): Promise<string> {
    return await post<number | null, string>(`${__SERVER_API__}/api/courses/${courseId}/coaches`, auth, postData);
}

export async function createCourse(auth: Auth,groupId: number, courseData: CourseConfigs): Promise<string> {
    return await post<{}, string>(`${__SERVER_API__}/api/groups/${groupId}/courses`, auth, courseData);
}
export async function deleteCourse(auth: Auth, courseId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/courses/${courseId}`, auth);
}

export async function updateCourse(auth: Auth,courseId: number, courseData: CourseConfigs): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/courses/${courseId}`, auth, courseData);
}

export async function saveImage(auth: Auth,image: File): Promise<string> {
    return await postImage<{}, string>(`${__SERVER_API__}/api/images`,auth,image);
}

export async function updateCourseImage(auth: Auth,courseId: number, image: any): Promise<string> {
    return await updateImage<{}, string>(`${__SERVER_API__}/api/courses/${courseId}/image`, auth,image);
}

export async function createLesson(auth: Auth,courseId: number, lessonData: LessonConfigs): Promise<string> {
    return await post<{}, string>(`${__SERVER_API__}/api/courses/${courseId}/lessons`, auth, lessonData);
}

export async function deleteLesson(auth: Auth, lessonId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/lessons/${lessonId}`, auth);
}

export async function updateLesson(auth: Auth,lessonId: number, lessonData: LessonConfigs): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/lessons/${lessonId}`, auth, lessonData);
}

export async function createContent(auth: Auth,lessonId: number, contentData: ContentConfigs): Promise<string> {
    return await post<{}, string>(`${__SERVER_API__}/api/lessons/${lessonId}/contents`, auth, contentData);
}

export async function deleteContent(auth: Auth, contentId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/contents/${contentId}`, auth);
}

export async function updateContent(auth: Auth,contentId: number, contentData: Form): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/contents/${contentId}`, auth, contentData);
}

export async function removeImage(auth: Auth, courseId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/courses/${courseId}/image`, auth);
}

export async function createContentForms(auth: Auth,lessonId: number, contentData: ContentConfigs): Promise<string> {
    return await post<{}, string>(`${__SERVER_API__}/api/lessons/${lessonId}/forms`, auth, contentData);
}

export async function updateContentForms(auth: Auth,formId: number, contentData: ContentConfigs): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/forms/${formId}`, auth, contentData);
}

export async function deleteContentForms(auth: Auth, formId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/forms/${formId}`, auth);
}
export async function getContent(auth: Auth, contentId: number): Promise<any> {
  return await get(`${__SERVER_API__}/api/contents/${contentId}`, auth);
}

export async function createQuestions(auth: Auth,formId: number, formData: Question): Promise<number> {
    return await post<{}, number>(`${__SERVER_API__}/api/forms/${formId}/questions`, auth, formData);
}

export async function getFormContent(auth: Auth, formId: number): Promise<Form> {
    return await get(`${__SERVER_API__}/api/forms/${formId}`, auth);
  }

  export async function getMeetingContent(auth: Auth, meetingId: number): Promise<Form> {
    return await get(`${__SERVER_API__}/api/meetings/${meetingId}`, auth);
  }

  export async function deleteQuestions(auth: Auth, questionId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/questions/${questionId}`, auth);
}

export async function createQuestionOptions(auth: Auth, questionId: number, options: QuestionOption): Promise<string> {
      return await post<{}, string>(`${__SERVER_API__}/api/questions/${questionId}/options`, auth, options);
}

export async function createSubQuestions(auth: Auth, questionId: number, options: QuestionOption): Promise<string> {
  return await post<{}, string>(`${__SERVER_API__}/api/questions/${questionId}/subQuestions`, auth, options);
}

export async function updateQuestion(auth: Auth,questionId: number, contentData: Question): Promise<string> {
  return await update<{}, string>(`${__SERVER_API__}/api/questions/${questionId}`, auth, contentData);
}

export async function setExpectedOptions(auth: Auth, questionId: number, expectedAnswer: number): Promise<number> {
    return await post<{}, number>(`${__SERVER_API__}/api/questions/${questionId}/expectedOptions`, auth, expectedAnswer);
}

export async function addExpectedAnswer(auth: Auth,questionId: number, expectedAnswer: string): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/questions/${questionId}/expectedAnswer`, auth,expectedAnswer);
}

export async function setExpectedSubQuestionAnswer(auth: Auth, subQuestionId: number, expectedAnswer: number): Promise<void> {
  return await post<{}, void>(`${__SERVER_API__}/api/subQuestions/${subQuestionId}/expectedOptions`, auth, expectedAnswer);
}

export async function updateQuestionOptions(auth: Auth,optionId: number, questionOption: QuestionOption): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/options/${optionId}`, auth,questionOption);
}

export async function updateExpectedAnswer(auth: Auth,questionId: number, expectedAnswer: string): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/questions/${questionId}/expectedAnswer`, auth,expectedAnswer);
}

export async function updateSubQuestionOptions(auth: Auth,subQuestionId: number, subQuestionOption: SubQuestion): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/subQuestions/${subQuestionId}`, auth,subQuestionOption);
}
export async function deleteOption(auth: Auth,optionId: number): Promise<string> {
  return await remove(`${__SERVER_API__}/api/options/${optionId}`, auth);
}
export async function deleteSubQuestion(auth: Auth,subQuestionId: number): Promise<string> {
  return await remove(`${__SERVER_API__}/api/subQuestions/${subQuestionId}`, auth);
}

export async function deleteSubQuestionExpectedAnswer(auth: Auth, subQuestionId: number, optionId: number): Promise<boolean> {
  return await remove(`${__SERVER_API__}/api/subQuestions/${subQuestionId}/expectedOptions/${optionId}`, auth);
}

export async function deleteQuestionExpectedAnswer(auth: Auth, answerId: number): Promise<boolean> {
  return await remove(`${__SERVER_API__}/api/expectedOptions/${answerId}`, auth);
}

export async function updateGroup(auth: Auth,groupId: number, groupData: GroupConfigs): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/groups/${groupId}`, auth, groupData);
}

export async function createMeeting(auth: Auth,lessonId: number, meetingData: ContentConfigs): Promise<string> {
    return await post<{}, string>(`${__SERVER_API__}/api/lessons/${lessonId}/meetings`, auth, meetingData);
}

export async function deleteMeeting(auth: Auth, meetingId: number): Promise<boolean> {
    return await remove(`${__SERVER_API__}/api/meetings/${meetingId}`, auth);
}

export async function updateMeeting(auth: Auth,meetingId: number, meetingData: ContentConfigs): Promise<string> {
    return await update<{}, string>(`${__SERVER_API__}/api/meetings/${meetingId}`, auth, meetingData);
}
export async function updateCourseStatus(auth: Auth,courseId: number, courseStatus: string): Promise<string> {
  return await update<{}, string>(`${__SERVER_API__}/api/courses/${courseId}/status`, auth, courseStatus);
}