import * as React from 'react';
import plusIcon from '../../assets/img/plus.png'
import minusIcon from '../../assets/img/minus.png'
import { TextI } from '../Forms/TextI';
import { CheckboxI } from '../Forms/CheckboxI';

export interface QuizOptionFieldProps {
    index: number;
    active: boolean;
    placeholder?: string;
    value?: string;
    isValid: boolean;
    isInvalid: boolean;
    onChange: (typedValue: string, index: number) => void;
    onAddOption: (index: number) => void;
    onRemoveOption: (index: number) => void;
    isEditing?: boolean;
    showSpinner?: boolean;
    onCompleteEdit?: () => void
}

export const QuizOptionField: React.FunctionComponent<QuizOptionFieldProps> = ({ 
  index, 
  active, 
  placeholder, 
  value, 
  isValid, 
  isInvalid, 
  onChange, 
  onAddOption, 
  onRemoveOption, 
  isEditing, 
  showSpinner,
  onCompleteEdit }) => {

    const addOptionHandler = () => {
        onAddOption(index)
    }
    const removeOptionHandler = () => {
        onRemoveOption(index)
    }

    const onChangeHandler = (value: string) => {
        onChange(value, index)
    }
    return (
        <div className="d-flex full-width" onBlur={onCompleteEdit}>            
            <TextI
                active={active}
                placeholder={placeholder}
                value={value}
                isValid={isValid}
                isInvalid={isInvalid}
                onChange={onChangeHandler}
                showSpinner={showSpinner}
            />
            <img className="survey-field-icon" alt="Add another choice" title="Add another choice" style={{cursor: "pointer"}} src={plusIcon} onClick={addOptionHandler} />
            <img className="survey-field-icon" alt="Delete this choice" title="Delete this choice" style={{cursor: "pointer"}} src={minusIcon} onClick={removeOptionHandler} />
        </div>
    );
}