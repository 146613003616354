import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { CoachCourse, Trainee } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { CoachCourseHeader } from './components/main/CoachCourseHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { CardSection, CardViewType } from '../../../components/Cards/CardSection';
import { EffectContentArea } from '../../../components/EffectContentArea';
import { TraineeStatusCard } from './components/tab1/TraineeStatusCard';

export interface CoachCourseDashboardPageRouteParameters {
    groupId: number;
    courseId: number;
}

export interface CoachCourseDashboardPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
}

type State = {
    apiError?: any | undefined;
    course?: CoachCourse;
    trainees?: Trainee[];
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: CoachCourse): State;
    LoadTrainees(trainees: Trainee[]): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadTrainees: trainees => ({ ...state, trainees }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const CoachCourseDashboardPage: React.FunctionComponent<CoachCourseDashboardPageProps> = ({ auth, onLogout, groupId, courseId }) => {
    const { t } = useTranslation();
    const [{ apiError, course, trainees, tabIndex }, actions] = wrapperReducer(useReducer(reducer, { tabIndex: 1 }));

    const goToRoot = () => Router.push('/');
    const goToMyCourses = () => Router.push('/mycourses');

    useLoadCourse(auth, courseId, actions);
    useLoadTrainees(auth, courseId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!course || !trainees) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myCourses'), onClick: goToMyCourses },
                { text: `${course.name}`, active: true }
            ]}
            onLogout={onLogout}
        >

            <CoachCourseHeader
                courseStatus={course.status || 'unknown'}
                courseType={course.type || ''}
                courseName={course.name}
                courseImgUrl={course.imageUrl}
                groupName={course.groupName}

                traineeCount={course.traineeCount || 0}
                notStartedCount={course.notStartedCount || 0}
                inProgressCount={course.inProgressCount || 0}
                finishedCount={course.finishedCount || 0}
            />

            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('CourseDashboardPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('CourseDashboardPage.tab1')}
                    </a>
                </li>
            </PageNav>

            {tabIndex === 0 && (
                <div className="main mt-3 mt-md-4">
                    <div className="container-fluid">
                        <div className="card bg-white px-4 pt-3">
                            <CardSection title={t('CourseDashboardPage.tab0_title')}>
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />
                                    <EffectContentArea rawHTML={course.body || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
                                </div>
                            </CardSection>
                        </div>
                    </div>
                </div>
            )}

            {tabIndex === 1 && (
                <div className="mt-3 mt-md-4">
                    <div className="container-fluid">

                        <div className="row">
                            {(trainees || []).map((trainee, i) => (
                                <TraineeStatusCard
                                    key={i}
                                    cardViewType={CardViewType.List}
                                    name={trainee.name}
                                    email={trainee.email}
                                    startDate={trainee.startDate}
                                    traineeLastAccessDate={trainee.traineeLastAccessDate}
                                    coachLastAccessDate={trainee.coachLastAccessDate}
                                    finishDate={trainee.finishDate}
                                    progress={trainee.progress}
                                    lastLessonName={trainee.lastLessonName}
                                    lastTaskAssignedTo={trainee.lastTaskAssignedTo}
                                    lastTaskName={trainee.lastTaskName}
                                    lastTaskStatus={trainee.lastTaskStatus}
                                    onNavigateToLessonsClick={() => Router.push(`/groups/${groupId}/coach/courses/${courseId}/trainee/${trainee.id}`)}
                                />
                            ))}
                        </div>

                    </div>
                </div>
            )}

        </BaseAppLayout>
    );
}

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getCoachCourse(auth, courseId);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadTrainees(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const trainees = await services.getCoachTrainees(auth, courseId);
            if (isRequestError(trainees)) {
            }
            else {
                actions.send(a => a.LoadTrainees(trainees));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}