import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { scrollToPosition } from '../../../api/utils';
import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { EditableForm, AnswersData, Question } from '../../../domain/models';
import { extractInitialFrom, updateSingleAnswer, missingRequiredAnswers, isOwnTask, isAnswerable, isAnswered } from '../../../components/Task/synchronous-helpers';

import { P50x } from '../../../components/P50x';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { ButtonBack } from '../../../components/Buttons/ButtonBack';
import { FeedbackBox } from '../../../components/Task/FeedbackBox';
import { EditableFormFeedback } from '../../../components/Task/EditableFormFeedback';
import { CountdownTrigger } from '../../../components/Task/CountdownTrigger';
import { CardSection, CardSectionLoading, CardViewType } from '../../../components/Cards/CardSection';
import { ClickableContentArea } from '../../../components/ClickableContentArea';
import { QuestionsList } from '../../../components/Forms/QuestionsList';
import { SubmitForm } from '../../../components/Buttons/SubmitForm';
import { CancelForm } from '../../../components/Buttons/CancelForm';

export interface EditableFormPageRouteParameters {
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

export interface EditableFormPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    lessonId: number;
    taskId: number;
}

type State = {
    apiError?: any | undefined;
    editableForm?: EditableForm;
    answers?: AnswersData;
    missingAnswers?: Question["id"][];
    operation?: string;
    lock?: boolean;
    submitted?: boolean;
    newStatus?: string;
    showFeedback?: boolean;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadEditableForm(editableForm: EditableForm): State;
    SetAnswers(answers: AnswersData): State;
    CheckAnswers(missingAnswers: Question["id"][]): State;
    RemoveMissingAnswer(missingAnswers: Question["id"][], questionId: number): State;
    InactivateForm(): State;
    SendForm(operation: string): State;
    UnlockSend(): State;
    PostStatus200(newStatus: string): State;
    ToggleFeedback(showFeedback: boolean): State;
    ReactivateForm(): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadEditableForm: editableForm => ({ ...state, editableForm }),
    SetAnswers: answers => ({ ...state, answers }),
    CheckAnswers: missingAnswers => ({ ...state, missingAnswers }),
    RemoveMissingAnswer: (missingAnswers, questionId) => ({ ...state, missingAnswers: missingAnswers.filter((i) => i !== questionId) }),
    InactivateForm: () => ({ ...state, submitted: true }),
    SendForm: operation => ({ ...state, operation, lock: true }),
    UnlockSend: () => ({ ...state, lock: false }),
    PostStatus200: newStatus => ({ ...state, lock: false, submitted: true, newStatus }),
    ToggleFeedback: showFeedback => ({ ...state, showFeedback }),
    ReactivateForm: () => ({ ...state, submitted: false })
})

export const EditableFormPage: React.FunctionComponent<EditableFormPageProps> = ({
    auth,
    onLogout,
    groupId,
    courseId,
    lessonId,
    taskId
}) => {
    const { t } = useTranslation();
    const [{
        apiError,
        editableForm,
        answers,
        missingAnswers,
        operation,
        lock,
        submitted,
        newStatus,
        showFeedback
    }, actions] = wrapperReducer(useReducer(reducer, {
        lock: false,
        submitted: false,
        showFeedback: false,
    }));

    const goToRoot = () => Router.push('/');
    const goToLessons = () => Router.push(`/groups/${groupId}/trainee/courses/${courseId}`);

    useLoadEditableForm(auth, taskId, actions);
    useSendEditableForm(lock || false, submitted || false, auth, taskId, operation || '', answers || {}, missingAnswers || [], actions);

    if (apiError) return <P50x onGoBack={goToRoot} />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.lessons'), onClick: goToLessons },
                { text: t('Breadcrumbs.editableForm'), active: true }
            ]}
            onLogout={onLogout}
        >

            <div className="header-page hasBtnBack hasInfo">
                <ButtonBack text={t('TaskPage.goBack')} onClick={goToLessons} />
                <h1 className="h4 header-page-title title-color">{t('TaskPage.task')}</h1>
            </div>

            {(editableForm)
                ? (
                    <>
                        {showFeedback && <FeedbackBox>
                            <EditableFormFeedback
                                isOwnTask={isOwnTask(editableForm, 'trainee')}
                                status={(!newStatus) ? editableForm.status : newStatus}
                                activeResume={false}
                                onResume={() => { }}
                                activeEdit={!!submitted}
                                onEdit={() => actions.send(a => a.ReactivateForm())}
                            />
                            {(newStatus === 'Finished') &&
                                <CountdownTrigger
                                    cancellable={true}
                                    initialSeconds={2}
                                    onTick={() => { }}  // OBS.: optional 1s trigger
                                    onTrigger={goToLessons}
                                />
                            }
                        </FeedbackBox>}

                        <div className="card bg-white px-4 pt-3">
                            <CardSection
                                title={editableForm.name}
                                showGridListToggle={false}
                                initialCardViewType={CardViewType.List}
                            >
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />

                                    {editableForm.body && (
                                        <>
                                            <ClickableContentArea
                                                rawHTML={editableForm.body || ''}
                                                onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))}
                                            />
                                            <hr className="mt-0 mb-4" />
                                        </>
                                    )}

                                    <QuestionsList
                                        active={isAnswerable(editableForm, 'trainee') && !lock && !submitted}
                                        visible={isOwnTask(editableForm, 'trainee') || !editableForm.isResponsePrivate}
                                        answered={isAnswered(editableForm)}
                                        questions={editableForm.questions}
                                        currentValues={answers}
                                        missingAnswers={missingAnswers}
                                        onChange={(type, id, val) => {
                                            actions.send(a => a.SetAnswers(updateSingleAnswer(type, id, val, answers)));
                                            (!!missingAnswers) && actions.send(a => a.RemoveMissingAnswer(missingAnswers, id));
                                        }}
                                    />

                                    <hr />

                                    <div className="col-12 text-right">
                                        {(isAnswerable(editableForm, 'trainee'))
                                            ? (editableForm.status !== 'Finished')
                                                ? (<>
                                                    <SubmitForm
                                                        active={!lock && !submitted}
                                                        label={t('TaskPage.submitBtn')}
                                                        loading={!!lock && (operation === 'submit')}
                                                        onSubmit={() => {
                                                            actions.send(a => a.CheckAnswers(missingRequiredAnswers(editableForm, answers)));
                                                            actions.send(a => a.SendForm('submit'));
                                                        }}
                                                    />
                                                    <CancelForm
                                                        active={!lock && !submitted}
                                                        label={t('TaskPage.cancelBtn')}
                                                        onCancel={() => { actions.send(a => a.SetAnswers({})); goToLessons(); }}
                                                    />
                                                </>)
                                                : (!submitted)
                                                    ? (<>
                                                        <SubmitForm
                                                            active={!lock && !submitted}
                                                            label={t('TaskPage.updateBtn')}
                                                            loading={!!lock && (operation === 'submit')}
                                                            onSubmit={() => {
                                                                actions.send(a => a.CheckAnswers(missingRequiredAnswers(editableForm, answers)));
                                                                actions.send(a => a.SendForm('submit'));
                                                            }}
                                                        />
                                                        <CancelForm
                                                            active={!lock && !submitted}
                                                            label={t('TaskPage.cancelBtn')}
                                                            onCancel={() => { actions.send(a => a.SetAnswers({})); goToLessons(); }}
                                                        />
                                                    </>)
                                                    : (<ButtonBack text={t('TaskPage.goBack')} btnDefault={true} onClick={goToLessons} />)
                                            : (<ButtonBack text={t('TaskPage.goBack')} btnDefault={true} onClick={goToLessons} />)
                                        }
                                    </div>
                                    
                                </div>
                            </CardSection>
                        </div>
                    </>
                )
                : <CardSectionLoading description={true} showGridListToggle={true} initialCardViewType={CardViewType.List} />}

        </BaseAppLayout>
    );
}

const useLoadEditableForm = function (auth: Auth, editableFormId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const editableForm = await services.getTraineeEditableFormTask(auth, editableFormId);
            if (isRequestError(editableForm)) {
                // TODO: log the error
                // TODO: Diogo - create request error message
                // TODO: use error boundary
                console.log(editableForm);
            }
            else {
                actions.send(a => a.LoadEditableForm(editableForm));
                actions.send(a => a.SetAnswers(extractInitialFrom(editableForm)));
                scrollToPosition(0, 0);

                if (editableForm.status !== 'NotStarted') {
                    actions.send(a => a.InactivateForm());
                    actions.send(a => a.ToggleFeedback(true));
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

const useSendEditableForm = function (lock: boolean, submitted: boolean, auth: Auth, editableFormId: number, operation: string, answers: AnswersData, missingAnswers: Question["id"][], actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            if (lock && !submitted) {
                if (missingAnswers.length === 0) {
                    const status = await services.postTraineeEditableFormAnswer(auth, editableFormId, { op: operation, answers });
                    if (isRequestError(status)) {
                        // TODO: log the error
                        // TODO: Diogo - create request error message
                        // TODO: use error boundary
                        console.log(status);

                        // (TODO) unlock resend in any error case?
                        actions.send(a => a.UnlockSend());
                    }
                    else {
                        actions.send(a => a.PostStatus200(status));           // Freezes Submit/Cancel
                        actions.send(a => a.ToggleFeedback(true));
                        scrollToPosition(0, 0);
                    }
                } else {
                    actions.send(a => a.UnlockSend());
                    scrollToPosition(0, 0);
                }
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, [lock, submitted]);
}
