import _ from 'lodash';

import { Auth,isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { Member, CourseConfigs } from '../../../domain/models';
import * as Router from '../../../api/Router';
import { RequestList } from '../CourseManagementPage/components/tab2/DesignateCoachPopup';
import { async } from 'q';

export const approveGroupMembers = async (auth: Auth, groupId: number, selectedMembers: Member[], handleRequests: (failedRequests: number[]) => void, id?: number) => {

    let dispatchedRequest = 0
    let failedRequests: number[] = []
    let approveRequestList: RequestList[] = []
    let selectedUsers = []
    id ? selectedUsers.push({ 'id': id }) : selectedUsers = _.filter(selectedMembers, function (member) {
        return member.membershipStatus ? member.membershipStatus.toLowerCase() === 'pending' : false;
    })
    try {
        for (let i = 0; i < selectedUsers.length; i++) {
            const request = services.approveMembership(auth, groupId, selectedUsers[i].id)
            approveRequestList.push({ promise: request, traineeID: selectedUsers[i].id })
        }
        approveRequestList.forEach((request) => {
            request.promise
                .then(() => {
                    dispatchedRequest = dispatchedRequest + 1;
                    if (dispatchedRequest >= selectedUsers.length) {
                        handleRequests(failedRequests)
                    }
                })
                .catch((error) => {
                    dispatchedRequest = dispatchedRequest + 1;
                    failedRequests.push(request.traineeID)
                    if (dispatchedRequest >= selectedUsers.length) {
                        handleRequests(failedRequests)
                    }
                })
        })
    } catch (error) {
        return;
    }

}

export const deleteCourse = async (auth: Auth, courseId: number) => {
    try {
        return services.deleteCourse(auth,courseId);
    }
    catch (e) {
        return;
    }
}
export const getGroupCourses=async(auth:Auth,groupId:number)=>{
   return services.getGroupCourses(auth!, groupId);
  
}