import * as React from 'react';
import { useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, handleLink, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { AdminCourse, Lesson } from '../../../domain/models';

import { ParticipantsMgmtContext } from './components/tab2/ParticipantsMgmtContext';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { AdminCourseHeader } from './components/main/AdminCourseHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { CardSection } from '../../../components/Cards/CardSection';
import { EffectContentArea } from '../../../components/EffectContentArea';
import { AdminLessonPanel } from './components/tab1/AdminLessonPanel';
import { ParticipantsList } from './components/tab2/ParticipantsList';
import { SelfPacedParticipantsList } from './components/tab2/SelfPacedParticipantsList';
import { ButtonEdit } from '../../../components/Buttons/ButtonEdit';
import logoimage from '../CourseManagementPage/logo.png'
import { ParticipantLessonPanel } from '../../Participant/CourseParticipantPage/components/tab1/ParticipantLessonPanel';
import { RadioboxBlock } from '../../../components/Forms/RadioboxBlock';
import Modal from 'react-responsive-modal';
import { CourseStatus } from '../../../domain/enums';

export interface CourseManagementPageRouteParameters {
    groupId: number;
    courseId: number;
    tabId: number;
}

export interface CourseManagementPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
    courseId: number;
    tabId: number;
}

type State = {
    apiError?: any | undefined;
    course?: AdminCourse;
    lessons?: Lesson[];
    // (TBD) LessonsAggregateData for other case
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadCourse(course: AdminCourse): State;
    LoadLessons(lessons: Lesson[]): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadCourse: course => ({ ...state, course }),
    LoadLessons: lessons => ({ ...state, lessons }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const CourseManagementPage: React.FunctionComponent<CourseManagementPageProps> = ({ auth, onLogout, groupId, courseId, tabId }) => {
    const { state: { participantsList, apiErrorParticipant } } = useContext(ParticipantsMgmtContext);
    const [value, setValue] = React.useState();
    const [courseStatusPopUp, setCourseStatusPopUp] = React.useState(false)
    const { t } = useTranslation();
    const [{
        apiError,
        course,
        lessons,
        tabIndex

    }, actions] = wrapperReducer(useReducer(reducer, {
        tabIndex: Number(tabId)
    }));

    React.useEffect(() => {
      if(course && course.status!.toLowerCase() === CourseStatus.Test) {
        setValue(2)
      } else if(course && course.status!.toLowerCase() === CourseStatus.Draft) {
        setValue(1)
      }
    },[course])

    const onChangeCourseStatusHandler = () => {
      setCourseStatusPopUp(false)
      let courseStatus = value === 1 ? 'Test' : 'Draft'
      services.updateCourseStatus(auth!, courseId, courseStatus)
        .then(()=> {
          Router.push(`/groups/${groupId}/admin`)
          Router.push(`/groups/${groupId}/admin/courses/${courseId}/0`)
        })
        .catch((error) => {
          actions.send(a=> a.Set50xError(error))
        })
    }

    const goToRoot = () => Router.push('/');
    const goToMyGroups = () => Router.push('/mygroups');
    const goToAdminGroup = () => Router.push(`/groups/${groupId}/admin/0`);

    useLoadCourse(auth, courseId, actions);
    useLoadLessons(auth, courseId, actions);

    if (apiError || apiErrorParticipant) return <P50x onGoBack={goToRoot} />
    if (!course || !lessons || !participantsList) return <LoadingPage />

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myGroups'), onClick: goToMyGroups },
                { text: `${course.groupName}`, onClick: goToAdminGroup },
                { text: `${course.name}`, active: true }
            ]}
            onLogout={onLogout}
        >
            <div className="col-md-12 header-display no-pad">
                <div className="col-md-2 no-pad image-position">
                    {course.imageUrl !== null &&
                        <img src={course.imageUrl} alt="preview" className="overview" />
                    }
                    {
                        course.imageUrl === null &&
                        <img src={logoimage} alt="preview" className="overview" />
                    }
                </div>
                <div className="col-md-10 no-pad">
                    <AdminCourseHeader
                        courseName={course.name}
                        groupName={course.groupName}
                        type={course.type}
                        groupMembership={course.isGroupMembershipRequired}
                        enrollmentApproval={course.isEnrollmentApprovalRequired}
                        status={course.status!}
                        description={course.description!}
                    />
                    {course.status!.toLowerCase() !== CourseStatus.Published&&
                      <div className="d-flex flex-row-reverse">
                        <RadioboxBlock
                          active={true}
                          answered={false}
                          questionBody={''}
                          options={[
                              { id: 1, body: 'Draft' },
                              { id: 2, body: 'Test' },
                          ]}
                          hideOptionLabels={false}
                          expectedOptions={[1]}
                          selected={value}
                          style={'d-flex flex-row justify-content-between width-150'}
                          onChange={() => setCourseStatusPopUp(true)}
                        />
                        </div>
                    }
                </div>

            </div>


            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('CourseManagementPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('CourseManagementPage.tab1')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 2 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(2))}>
                        {t('CourseManagementPage.tab2')}
                    </a>
                </li>
            </PageNav>

            {tabIndex === 0 && (
                <div className="main mt-3 mt-md-4">
                    <div className="container-fluid">
                        {course.status === 'Draft' &&
                            <div className="btn-align-right">
                                <ButtonEdit active={true} label={'Edit'} onEdit={() => Router.push(`/groups/${groupId}/admin/edit/courses/${courseId}`)} />
                            </div>
                        }
                        <div className="card bg-white px-4 pt-1 trumbowyg-editor">
                            <CardSection title={''}>
                                <div className="col-12">
                                    <hr className="mt-0 mb-4" />
                                    <EffectContentArea rawHTML={course.body || ''} onLinkClick={(url) => handleLink(url, t('miscelaneous.close'))} />
                                </div>
                            </CardSection>
                        </div>

                    </div>
                </div>
            )}

            {tabIndex === 1 && (
                <div className="mt-3 mt-md-4">
                    <div className="container-fluid">
                        {(course.status!.toLowerCase() === CourseStatus.Published  || course.status!.toLowerCase() === CourseStatus.Test )&&
                            <div>
                                {lessons.map((lesson, i) => (
                                    <ParticipantLessonPanel key={i} index={i + 1} lesson={lesson} ></ParticipantLessonPanel>
                                ))}
                            </div>
                        }
                        {course!.status!.toLowerCase() === "draft" &&
                            <div className="lessons-builder-container">
                                <AdminLessonPanel lessons={lessons} courseId={courseId} groupId={groupId} courseType={course.type}></AdminLessonPanel>
                            </div>
                        }

                    </div>
                </div>
            )}

            {tabIndex === 2 && (
                <div className="mt-3 mt-md-4">
                    <div className="container-fluid">

                        {course.type === 'CoachAssisted' &&
                            <ParticipantsList courseId={courseId} groupId={groupId} status={course.status!} />
                        }
                        {course.type === 'SelfPaced' &&
                            <SelfPacedParticipantsList courseId={courseId} groupId={groupId} status={course.status!}/>
                        }

                    </div>
                </div>
            )}

            <Modal open={courseStatusPopUp} onClose={() => setCourseStatusPopUp(false)} center>
            <br/>
                <p>
                    {value === 2&& t('CourseManagementPage.courseProgressLost')}{t('miscelaneous.confirm')}
                </p> 
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => setCourseStatusPopUp(false)}>{t('miscelaneous.cancel')}</button>
                    <button type="button" className="btn btn-primary"  onClick={onChangeCourseStatusHandler}>{t('miscelaneous.yes')}</button>

                </div>
            </Modal>

        </BaseAppLayout>
    );
}

function useLoadCourse(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const course = await services.getAdminCourse(auth, courseId);
            if (isRequestError(course)) {
            }
            else {
                actions.send(a => a.LoadCourse(course));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadLessons(auth: Auth, courseId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const lessons = await services.getParticipantCourseLessons(auth, courseId);
            if (isRequestError(lessons)) {
            }
            else {
                actions.send(a => a.LoadLessons(lessons));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

