import { __SERVER_API__ } from '.';
import { Auth, get, post } from '../_shared';
import {
    MemberGroup,
    Course,
    MembersList,
} from '../models';

export async function getMemberGroup(auth: Auth, groupId: number): Promise<MemberGroup> {
    return await get(`${__SERVER_API__}/api/groups/${groupId}`, auth);
}

export async function getGroupCourses(auth: Auth, groupId: number): Promise<Course[]> {
    return await get(`${__SERVER_API__}/api/groups/${groupId}/courses`, auth);
}

// (TODO) discuss if Member can see other Members without being Admin (and edit Membership with other endpoint)
export async function getGroupMembers(auth: Auth, groupId: number): Promise<MembersList> {
    // return await get(`${__SERVER_API__}/api/groups/${groupId}/members`, auth); //(TODO) pending endpoint
    return new Promise((resolve, reject) => {
        resolve({
            page: 0,
            pagesCount: 1,
            results: [
                { id: 1, name: 'Member Name 1', email: 'email1@email.com', membershipStatus: 'Member', joinDate: '2019-07-24T08:02:14.34', isAdmin: false },
                { id: 2, name: 'Member Name 2', email: 'email2@email.com', membershipStatus: 'Member', joinDate: '2019-07-24T08:02:14.34', isAdmin: true },
                { id: 2, name: 'Member Name 3', email: 'email3@email.com', membershipStatus: 'Member', joinDate: '2019-07-24T08:02:14.34', isAdmin: false }
            ],
            resultsCount: 2
        })
    });
}
