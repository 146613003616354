import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdown } from './ButtonDropdown';

export interface AdminCourseHeaderProps {
    courseName: string;
    groupName?: string;
    type:string;
    groupMembership?:boolean;
    enrollmentApproval?:boolean;
    status: string;
    description:string;
}

export const AdminCourseHeader: React.FunctionComponent<AdminCourseHeaderProps> = ({
    courseName,
    groupName,
    type,
    groupMembership,
    enrollmentApproval,
    status,
    description
}) => {
    const { t } = useTranslation();

    return (
        <div className="course-header">
            <div >
                <div className="col-md-12 col-sm-12 no-pad display-toggle" >
                    <div className="col-md-9 no-pad" style={{display:"block"}}>
                        <h1 className="h4 mt-2 mb-3 mt-md-0 mb-md-0 title-color">{courseName}</h1>
                        <div className="col-md-11 col-sm-12 nopad nomargin mt-2 mt-md-2">
                            <strong>{t('CourseHeader.group')}:</strong>
                            {' '} {groupName}
                            <div className="card-text text-muted mt-1 mt-md-2 mb-1  d-sm-block">
                        {description}
                    </div>
                        </div>
                    </div>
                    <div className="display-toggle col-md-3 no-pad div-outer" style={{ display: "block" }}>
                        <div className="div-inner">
                        <div className="toggle-position ">{enrollmentApproval ? 'Manual Enrollment Approval' : ''}</div>
                        <div className="toggle-position">{groupMembership ? 'Group Membership' : ''}</div>
                        <div className="toggle-position margin-bottom-15">{type.toUpperCase() === 'SELFPACED' ? 'Self paced' : 'Coach-assisted'}</div>
                        </div>
                    </div>                  
                </div>

            </div>
        </div>
    )
}
