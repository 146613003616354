/**
 * Global 'window' properties set in %PUBLIC_URL%/config.js
 */
export const __SERVER_REPO__: string = (window as any).REPO_URL || 'https://galileeone.blob.core.windows.net/galilee';

/**
 * Global
 */
export const APP_Language_List = ['en-US', 'pt-BR', 'zh-Hans', 'zh-Hant'];
export const validLanguage = function (language?: string): boolean { return !!language && typeof language === 'string' && (APP_Language_List.indexOf(language) !== -1) };
export const APP_Timezone_List = ['America/Chicago', 'America/Sao_Paulo', 'Etc/UTC', 'Asia/Taipei'];
export const validTimezone = function (timezone?: string): boolean { return !!timezone && typeof timezone === 'string' && (APP_Timezone_List.indexOf(timezone) !== -1) };
export const APP_Default_Language = 'en-US';
export const APP_Default_Timezone = 'America/Chicago';
export const APP_Valid_URLs_Regex = /^(\b(https?|ftp(s)?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]$/;
export const validURL = function (url?: string): boolean { return !!APP_Valid_URLs_Regex.test(url || ''); };

/**
 * Profile
 */
export const APP_Default_UUID = -999999;
export const APP_Email_Regex = /^.+@[^\.].*\.[a-z]{2,}$/;
export const validEmail = function (email?: string): boolean { return !!APP_Email_Regex.test(email || ''); };
export const APP_Password_Regex = /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,20})$/;
export const validPassword = function (password?: string): boolean { return !!APP_Password_Regex.test(password || ''); };
/**
 * At least:
 * 1 uppercase
 * 1 lowercase
 * 1 digit
 * 1 special character
 * 8 characters (maximum 20)
 */

/**
 * Task Forms
 */
//const DB_DatePattern = 'YYYY-MM-DDTHH:mm:ss.sss';
export const APP_DatePattern = 'YYYY-MM-DD';
export const APP_DateTimePattern = 'YYYY-MM-DDTHH:mm';
export const APP_TimePattern = 'HH:mm';                 // OBS.: ignoring seconds
