export * from './profile';
export * from './group';
export * from './member';
export * from './course';
export * from './participant';
export * from './coach';
export * from './trainee';
export * from './lesson';
export * from './task';
export * from './question';
export * from './answer';

export interface BasicModel {
    id: number;
    name: string;
    /**/
    description?: string;
    body?: string;
    startDate?: any;
    finishDate?: any;
}
