import * as React from 'react';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import * as Router from '../../../api/Router';

import { ActionMatcher, ActionDispatcher, wrapperReducer, useAsyncEffect } from '../../../api/react-helper';
import { Auth, isRequestError } from '../../../domain/_shared';
import * as services from '../../../domain/services';
import { MemberGroup, Course, MembersList } from '../../../domain/models';

import { P50x } from '../../../components/P50x';
import { LoadingPage } from '../../../components/LoadingPage';
import { BaseAppLayout } from '../../../layouts/shared/BaseAppLayout';
import { NonAdminGroupHeader } from './components/main/NonAdminGroupHeader';
import { PageNav } from '../../../components/Navs/PageNav';
import { GroupInfo } from './components/tab0/GroupInfo';
import { RO_GroupCoursesList } from './components/tab1/RO_GroupCoursesList';
import { RO_GroupMembersList } from './components/tab2/RO_GroupMembersList';

export interface GroupMembershipPageRouteParameters {
    groupId: number;
}

export interface GroupMembershipPageProps {
    auth: Auth;
    onLogout: () => void;
    groupId: number;
}

type State = {
    apiError?: any | undefined;
    memberGroup?: MemberGroup;
    courses?: Course[];
    // members?: MembersList;
    tabIndex: number;
}

type Action = {
    Set50xError(apiError: any): State;
    LoadMemberGroup(memberGroup: MemberGroup): State;
    LoadGroupCourses(courses: Course[]): State;
    // LoadGroupMembers(members: MembersList): State;
    ChangeTabIndex(index: number): State;
}

const reducer = (state: State, action: ActionMatcher<Action, State>) => action.match({
    Set50xError: apiError => ({ ...state, apiError }),
    LoadMemberGroup: memberGroup => ({ ...state, memberGroup }),
    LoadGroupCourses: courses => ({ ...state, courses }),
    // LoadGroupMembers: members => ({ ...state, members }),
    ChangeTabIndex: index => ({ ...state, tabIndex: index }),
})

export const GroupMembershipPage: React.FunctionComponent<GroupMembershipPageProps> = ({ auth, onLogout, groupId }) => {
    const { t } = useTranslation();
    const [{ apiError, memberGroup, courses/*, members*/, tabIndex }, actions] = wrapperReducer(useReducer(reducer, { tabIndex: 0 }));

    const goToRoot = () => Router.push('/');
    const goToMyGroups = () => Router.push('/mygroups');

    useLoadMemberGroup(auth, groupId, actions);
    useLoadGroupCourses(auth, groupId, actions);
    // useLoadGroupMembers(auth, groupId, actions);

    if (apiError) return <P50x onGoBack={goToRoot} />
    if (!memberGroup || !courses /*|| !members*/) return <LoadingPage />;

    return (
        <BaseAppLayout
            breadcrumbs={[
                { text: t('Breadcrumbs.home'), onClick: goToRoot },
                { text: t('Breadcrumbs.myGroups'), onClick: goToMyGroups },
                { text: `${memberGroup.name}`, active: true }
            ]}
            onLogout={onLogout}
        >

            <NonAdminGroupHeader
                groupName={memberGroup.name}
                userCount={memberGroup.userCount}
                creationDate={memberGroup.creationDate}
            />

            <PageNav>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 0 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(0))}>
                        {t('GroupMembershipPage.tab0')}
                    </a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 1 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(1))}>
                        {t('GroupMembershipPage.tab1')}
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a className={`nav-link ${tabIndex === 2 ? 'active' : ''}`} href="javascript:void(0)" onClick={() => actions.send(a => a.ChangeTabIndex(2))}>
                        {t('GroupMembershipPage.tab2')}
                    </a>
                </li> */}
            </PageNav>

            {tabIndex === 0 && (
                <GroupInfo
                    content={memberGroup.body || ''}
                    onClickViewCourses={() => actions.send(a => a.ChangeTabIndex(1))}
                />
            )}

            {tabIndex === 1 && (
                <RO_GroupCoursesList groupId={groupId} courses={courses} />
            )}

            {/* {tabIndex === 2 && (
                <RO_GroupMembersList groupId={groupId} members={members} />
            )} */}

        </BaseAppLayout>
    );
}

function useLoadMemberGroup(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const memberGroup = await services.getMemberGroup(auth, groupId);
            if (isRequestError(memberGroup)) {
                console.log(memberGroup);
            }
            else {
                actions.send(a => a.LoadMemberGroup(memberGroup));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

function useLoadGroupCourses(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
    useAsyncEffect(async () => {
        try {
            const courses = await services.getGroupCourses(auth, groupId);
            if (isRequestError(courses)) {
                console.log(courses);
            } else {
                actions.send(a => a.LoadGroupCourses(courses));
            }
        }
        catch (e) {
            actions.send(a => a.Set50xError(e));
        }
    }, []);
}

// function useLoadGroupMembers(auth: Auth, groupId: number, actions: ActionDispatcher<Action, State>) {
//     useAsyncEffect(async () => {
//         try {
//             const members = await services.getGroupMembers(auth, groupId);
//             if (isRequestError(members)) {
//                 console.log(members);
//             } else {
//                 actions.send(a => a.LoadGroupMembers(members));
//             }
//         }
//         catch (e) {
//             actions.send(a => a.Set50xError(e));
//         }
//     }, []);
// }
